

.body12 {
    /* background: wheat; */
    /* color: #485e74; */
    line-height: 1.6;
    font-family: 'Segoe UI', serif;
    padding: 1em;
  }
  .container123456 {
    max-width: 70vw;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    margin-top: 100px;
  }
  ul {
    list-style: none;
    padding: 0;
    
  }
  .brand {
    text-align: center;
    
  }
  .wrapper123 {
    box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
  }
  .wrapper123 > * {
    padding: 1em;
  }
  .company-info-list12{
    background: #F2923B;
    display: flex;
    flex-direction: column;
  }
  .company-info-list h3, .company-info-list ul{
    text-align: center;
    margin: 0 0 1rem 0;
   
  }
  .company-info-list ul li i{
    margin-right: 10px;
  }
  .contact {
    background: "#af9feff";
  }
  .contact form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    
  }
  .contact form label {
    display: block;
    font-weight: bold;
  }
  .contact form p{
    margin: 0;
  }
  .contact form .full {
    grid-column: 1/3;
  }
  .contact form button, .contact form input, .contact form textarea{
    width: 100%;
    padding: 1em;
    border: 1px solid #c9e6ff;
  }
  .contact form button {
    background: #c9e6ff;
    border: 0;
    text-transform: uppercase;
  }
  .contact form button{
    background: rgb(16, 82, 82);
    color: #fff;
    outline: 0;
    transition: all 0.5s ease-out;
  }
  @media(min-width: 700px){
    .wrapper123 {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
    .wrapper123 > * {
      padding: 2em;
    }
  }

  .company-info-list12 Input {
    background-color: white !important;
    color: black !important;
    font-weight: bold;
    font-size: 15px;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  