
.successSent{
    color: green;
}

.flat a:before {
    color: #000;
    box-shadow: 0 0 0 1px #ccc;
    display: flex !important;
    justify-content: center !important;
}
/* Example styles for DatePicker input box */
.react-datepicker-wrapper input {
    width: 200px; /* Set width */
    padding: 8px; /* Set padding */
    border: 1px solid #ccc; /* Set border */
    border-radius: 4px; /* Set border radius */
    font-size: 16px; /* Set font size */
    color: #333; /* Set text color */
    outline: none; /* Remove outline */
  }
  
  /* Example hover and focus styles */
  .react-datepicker-wrapper input:hover,
  .react-datepicker-wrapper input:focus {
    border-color: #007bff; /* Change border color on hover/focus */
  }
  






