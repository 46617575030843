.body12 {
  /* background: wheat; */
  /* color: #485e74; */
  /* line-height: 1.6; */
  font-family: "Segoe UI", serif;
  /* padding: 1em; */
}
.container123456 {
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  margin-top: 100px;
}
ul {
  list-style: none;
  padding: 0;
}
.brand {
  text-align: center;
}
.wrapper123 {
  box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
}
.wrapper123 > * {
  padding: 1em;
}
.company-info-list {
  background: #69a6ff;
  display: flex;
  flex-direction: column;
}
.company-info-list h3,
.company-info-list ul {
  text-align: center;
  margin: 0 0 1rem 0;
}
.company-info-list ul li i {
  margin-right: 10px;
}
.contact {
  background: "#af9feff";
}
.contact form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.contact form label {
  display: block;
  font-weight: bold;
}
.contact form p {
  margin: 0;
}
.contact form .full {
  /* grid-column: 1/3; */
}
.contact form button,
.contact form input,
.contact form textarea {
  width: 100%;
  padding: 1em;
  border: 1px solid #c9e6ff;
}
.contact form button {
  background: #c9e6ff;
  border: 0;
  text-transform: uppercase;
}
.contact form button {
  background: rgb(16, 82, 82);
  color: #fff;
  outline: 0;
  transition: all 0.5s ease-out;
}
@media (min-width: 700px) {
  .wrapper123 {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  .wrapper123 > * {
    padding: 2em;
  }
}

.company-info-list Input {
  background-color: white !important;
  font-weight: bold !important;
  color: black !important;
  font-size: 15px !important;
}

.body-crum {
  height: 10vh;
  text-align: center;
  font-family: "Merriweather sans" arial, verdana;
}
.breadcrumb {
  display: inline-block;
  box-shadow: 0 0 15px 1px #00000059;
  overflow: hidden;
  border-radius: 5px;
  counter-reset: flag;
  margin-bottom: 25px;
}
.breadcrumb a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  color: #fff;
  padding: 0 10px 0 60px;
  background: #666;
  background: linear-gradient(#666, #333);
  position: relative;
}
.breadcrumb a:first-child {
  padding-left: 46px;
  border-radius: 5px 0 0 5px;
}
.breadcrumb a:first-child::before {
  left: 14px;
}
.breadcrumb a:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 20px;
}
.breadcrumb a.active,
.breadcrumb a:hover {
  background: #333;
  background: linear-gradient(#333, #000);
}
.breadcrumb a.active::after,
.breadcrumb a:hover::after {
  background: #333;
  background: linear-gradient(135deg, #333, #000);
}
.breadcrumb a::after {
  content: "";
  position: absolute;
  top: 0;
  right: -18px;
  width: 36px;
  height: 36px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  background: #666;
  background: linear-gradient(135deg, #666, #333);
  box-shadow: 2px -2px 0 2px #00000066, 3px -3px 0 2px #ffffff1a;
  border-radius: 0 5px 0 50px;
}
.breadcrumb a:last-child::after {
  content: none;
}
.breadcrumb a::before {
  content: counter(flag);
  counter-increment: flag;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  background: #444;
  background: linear-gradient(#444, #222);
  font-weight: bold;
}
.flat a,
.flat a::after {
  background: #fff;
  color: #000;
  transition: all 0.5s;
}
.flat a::before {
  background: #fff;
  box-shadow: 0 0 0 1px #ccc;
}
.flat a:hover,
.flat a.active,
.flat a:hover::after,
.flat a.active::after {
  background: #1f2937;
  color: white;
}

.valid-email {
  border: 2px solid #1f2937;
  background-color: #1f2937;
  margin: 2px;
  padding: 2px;
  border-radius: 3px;
  display: none;
}

.wrong-email {
  /* border: 2px solid rgb(255, 0, 0); */
  background-color: rgb(245, 199, 199);
  border-radius: 3px;
}
.duplicate-email {
  border: 1px solid rgb(255, 17, 0);
  display: flex;
  flex-wrap: wrap;
  border-radius: 3px;
  width: auto;
  gap: 5px;
}

.groupCards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* width: 100%; */
  /* background-color: red; */
}
.cardsGroup {
  width: 200px;
  height: 100px;
  background-color: rgb(201, 245, 245);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.signature[type="text"]::placeholder {
  color: rgb(110, 94, 94);
  font-style: italic;
  white-space: pre-wrap !important;
  width: 200px;
  padding: 0, 10px;
}
.yellowCircle{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ff0000;
  
}
.signature-container br {
  display: none;
}
.react-quill p {
  margin: 0!important;
  padding: 0!important;
}