.body-hold {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    flex-direction: column;
    width: 100vw;
  }
  
  .kinetic {
    position: relative;
    height: 80px;
    width: 80px;
    margin-bottom: 80px;
  }
  
  .kinetic::before,
  .kinetic::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color:#4081EC;
    animation: rotateA 2s ease-in-out infinite 0.5s,
      color-change 2s ease-in-out infinite 1s;
  }
  
  .kinetic::before {
    animation: rotateB 2s ease-in-out infinite,
      color-change 2s ease-in-out infinite;
  }
  
  @keyframes rotateA {
    0%,
    25% {
      transform: rotate(0deg);
    }
  
    50%,
    75% {
      transform: rotate(180deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes rotateB {
    0%,
    25% {
      transform: rotate(90deg);
    }
  
    50%,
    75% {
      transform: rotate(270deg);
    }
  
    100% {
      transform: rotate(450deg);
    }
  }
  
  @keyframes color-change {
    75% {
      border-bottom-color: #E34133;
    }
  }

  .gmail-ani{
    animation: 2s holdon infinite;
  }
  @keyframes holdon {
    from {
        opacity: 0;
        transform: translateX(-120px);
    }
    to{
        opacity: 1;
        transform: translateX(20px);
    }
  }
  