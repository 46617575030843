

  
  .card-profile {
    background-color: #f8f4f4;
    border-radius: 10px;
    width: 400px;
    height: auto;
    margin: 0 auto 50px;
    /* overflow: hidden; */
    /* box-shadow: 4px 4px 6px gray; */
  }
  
  .card-profile>p {
    background-color: white;
    margin: 16px;
    font-size: 10px;
    line-height: 1.6;
    text-align: center;
    font-family: 'Inconsolata', monospace;
  }
  
  .card-profile h2 {
    background-color: inherit;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin: -27px 0px -10px;
    color: #ffffff;
    line-height: 1
  }
  

  
  .card-profile h2 + p {
    margin-top: 20px;
  }
  
  .header-profile {
    height: 150px;
    overflow: hidden;
    border-radius: 20px;
   
  }

  

  
  .socialmedia {
    text-align: center;
    background-color: inherit;
  }
  
  .socialmedia img {
    width: 35px;
    height: 35px;
    opacity: 0.85;
    background-color: inherit;
    margin: 5px;
    border-radius: 10px;
  }
  
  .socialmedia a {
    background-color: inherit;
  }
  
  .socialmedia img:hover {
    opacity: 0.6;
    transition-duration: 400ms;
    transform: scale(1.2);
  }
  .user-pro{
    color: black;
    font-weight: bold;
    letter-spacing: 1px;

  }
  .user-det{
    color: green;
    font-weight: bold;
    letter-spacing: 1.4px;

  }