/* all */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");



:root {
  --main-blue: #71b7e6;
  --main-purple: #9b59b6;
  --main-grey: #ccc;
  --sub-grey: #d9d9d9;
}
.body1234567{
    background: linear-gradient(135deg, var(--main-blue), var(--main-purple));
}
.container-add {
  max-width: 700px;
  width: 100%;
  background: #fff;
  padding: 25px 30px;
  border-radius: 5px;
  height: 80vh;
}
.container-add .title {
  font-size: 25px;
  font-weight: 500;
  position: relative;
}

.container-add .title::before {
  content: "";
  position: absolute;
  height: 3.5px;
  width: 30px;
  background: linear-gradient(135deg, var(--main-blue), var(--main-purple));
  left: 0;
  bottom: 0;
}

.container-add form .user__details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
/* inside the form user details */
form .user__details .input__box {
  width: calc(100% / 2 - 20px);
  margin-bottom: 15px;
}

.user__details .input__box .details {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}
.user__details .input__box input {
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid var(--main-grey);
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}

.user__details .input__box input:focus,
.user__details .input__box input:valid {
  border-color: var(--main-purple);
}

/* inside the form gender details */

.form-add .gender__details .gender__title {
  font-size: 20px;
  font-weight: 500;
}

.form-add .gender__details .category {
  display: flex;
  width: 80%;
  margin: 15px 0;
  justify-content: space-between;
}

.gender__details .category label {
  display: flex;
  align-items: center;
}

.gender__details .category .dot {
  height: 18px;
  width: 18px;
  background: var(--sub-grey);
  border-radius: 50%;
  margin: 10px;
  border: 5px solid transparent;
  transition: all 0.3s ease;
}

#dot-1:checked ~ .category .one,
#dot-2:checked ~ .category .two,
#dot-3:checked ~ .category .three {
  border-color: var(--sub-grey);
  background: var(--main-purple);
}

.form-add input[type="radio"] {
  display: none;
}

/* submit button */
.form-add .button {
  height: 45px;
  margin: 45px 0;
}

.form-add .button input {
  height: 100%;
  width: 100%;
  outline: none;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  background: linear-gradient(135deg, var(--main-blue), var(--main-purple));
  transition: all 0.3s ease;
}

.form-add .button input:hover {
  background: linear-gradient(-135deg, var(--main-blue), var(--main-purple));
}

@media only screen and (max-width: 584px) {
  .container-add {
    max-width: 100%;
  }

  .form-add .user__details .input__box {
    margin-bottom: 15px;
    width: 100%;
  }

  .form-add .gender__details .category {
    width: 100%;
  }

  .container-add form .user__details {
    max-height: 300px;
    overflow-y: scroll;
  }

  .user__details::-webkit-scrollbar {
    width: 0;
  }
}
.ant-select-selector{
    width: 300px !important;
}

/* bulk upload css */

/* General Styles */


  
  :root {
    --clr-white: rgb(255, 255, 255);
    --clr-black: rgb(0, 0, 0);
    --clr-light: rgb(245, 248, 255);
    --clr-light-gray: rgb(196, 195, 196);
    --clr-blue: rgb(63, 134, 255);
    --clr-light-blue: rgb(171, 202, 255);
  }
  
  .container-add-bulk {
    margin: 0;
    padding: 0;
    /* background-color: var(--clr-light); */
    color: var(--clr-black);
    font-family: 'Poppins', sans-serif;
    font-size: 1.125rem;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* End General Styles */
  
  /* Upload Area */
  .upload-area {
    width: 100%;
    max-width: 25rem;
    background-color: var(--clr-white);
    box-shadow: 0 10px 60px rgb(218, 229, 255);
    border: 2px solid var(--clr-light-blue);
    border-radius: 24px;
    padding: 2rem 1.875rem 5rem 1.875rem;
    margin: 0.625rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .upload-area--open { /* Slid Down Animation */
    animation: slidDown 500ms ease-in-out;
  }
  
  @keyframes slidDown {
    from {
      height: 28.125rem; /* 450px */
    }
  
    to {
      height: 35rem; /* 560px */
    }
  }
  
  /* Header */
  .upload-area__header {
  
  }
  
  .upload-area__title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.3125rem;
  }
  
  .upload-area__paragraph {
    font-size: 0.9375rem;
    color: var(--clr-light-gray);
    margin-top: 0;
  }
  
  .upload-area__tooltip {
    position: relative;
    color: var(--clr-light-blue);
    cursor: pointer;
    transition: color 300ms ease-in-out;
  }
  
  .upload-area__tooltip:hover {
    color: var(--clr-blue);
  }
  
  .upload-area__tooltip-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -125%);
    min-width: max-content;
    background-color: var(--clr-white);
    color: var(--clr-blue);
    border: 1px solid var(--clr-light-blue);
    padding: 0.625rem 1.25rem;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: none 300ms ease-in-out;
    transition-property: opacity, visibility;
  }
  
  .upload-area__tooltip:hover .upload-area__tooltip-data {
    opacity: 1;
    visibility: visible;
  }
  
  /* Drop Zoon */
  .upload-area__drop-zoon {
    position: relative;
    height: 11.25rem; /* 180px */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed var(--clr-light-blue);
    border-radius: 15px;
    margin-top: 2.1875rem;
    cursor: pointer;
    transition: border-color 300ms ease-in-out;
  }
  
  .upload-area__drop-zoon:hover {
    border-color: var(--clr-blue);
  }
  
  .drop-zoon__icon {
    display: flex;
    font-size: 3.75rem;
    color: var(--clr-blue);
    transition: opacity 300ms ease-in-out;
  }
  
  .drop-zoon__paragraph {
    font-size: 0.9375rem;
    color: var(--clr-light-gray);
    margin: 0;
    margin-top: 0.625rem;
    transition: opacity 300ms ease-in-out;
  }
  
  .drop-zoon:hover .drop-zoon__icon,
  .drop-zoon:hover .drop-zoon__paragraph {
    opacity: 0.7;
  }
  
  .drop-zoon__loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    color: var(--clr-light-blue);
    z-index: 10;
  }
  
  .drop-zoon__preview-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 0.3125rem;
    border-radius: 10px;
    display: none;
    z-index: 1000;
    transition: opacity 300ms ease-in-out;
  }
  
  .drop-zoon:hover .drop-zoon__preview-image {
    opacity: 0.8;
  }
  
  .drop-zoon__file-input {
    display: none;
  }
  
  /* (drop-zoon--over) Modifier Class */
  .drop-zoon--over {
    border-color: var(--clr-blue);
  }
  
  .drop-zoon--over .drop-zoon__icon,
  .drop-zoon--over .drop-zoon__paragraph {
    opacity: 0.7;
  }
  
  /* (drop-zoon--over) Modifier Class */
  .drop-zoon--Uploaded {
    
  }
  
  .drop-zoon--Uploaded .drop-zoon__icon,
  .drop-zoon--Uploaded .drop-zoon__paragraph {
    display: none;
  }
  
  /* File Details Area */
  .upload-area__file-details {
    height: 0;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    transition: none 500ms ease-in-out;
    transition-property: opacity, visibility;
    transition-delay: 500ms;
  }
  
  /* (duploaded-file--open) Modifier Class */
  .file-details--open {
    height: auto;
    visibility: visible;
    opacity: 1;
  }
  
  .file-details__title {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--clr-light-gray);
  }
  
  /* Uploaded File */
  .uploaded-file {
    display: flex;
    align-items: center;
    padding: 0.625rem 0;
    visibility: hidden;
    opacity: 0;
    transition: none 500ms ease-in-out;
    transition-property: visibility, opacity;
  }
  
  /* (duploaded-file--open) Modifier Class */
  .uploaded-file--open {
    visibility: visible;
    opacity: 1;
  }
  
  .uploaded-file__icon-container {
    position: relative;
    margin-right: 0.3125rem;
  }
  
  .uploaded-file__icon {
    font-size: 3.4375rem;
    color: var(--clr-blue);
  }
  
  .uploaded-file__icon-text {
    position: absolute;
    top: 1.5625rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--clr-white);
  }
  
  .uploaded-file__info {
    position: relative;
    top: -0.3125rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .uploaded-file__info::before,
  .uploaded-file__info::after {
    content: '';
    position: absolute;
    bottom: -0.9375rem;
    width: 0;
    height: 0.5rem;
    background-color: #ebf2ff;
    border-radius: 0.625rem;
  }
  
  .uploaded-file__info::before {
    width: 100%;
  }
  
  .uploaded-file__info::after {
    width: 100%;
    background-color: var(--clr-blue);
  }
  
  /* Progress Animation */
  .uploaded-file__info--active::after {
    animation: progressMove 800ms ease-in-out;
    animation-delay: 300ms;
  }
  
  @keyframes progressMove {
    from {
      width: 0%;
      background-color: transparent;
    }
  
    to {
      width: 100%;
      background-color: var(--clr-blue);
    }
  }
  
  .uploaded-file__name {
    width: 100%;
    max-width: 6.25rem; /* 100px */
    display: inline-block;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .uploaded-file__counter {
    font-size: 1rem;
    color: var(--clr-light-gray);
  }
